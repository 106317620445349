import React, { useEffect, useState } from "react";
import axios from "axios";
import { SearchIcon } from "@heroicons/react/solid";
import "./Header.css";
import { FaInstagram, FaLocationArrow, FaMailBulk } from "react-icons/fa";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import { AiFillPhone } from "react-icons/ai";
import logo from "../images/banner.png";
import { NavLink, Link } from "react-router-dom";
import HeaderContactButton from "./HeaderContactButton";
import instagram from "../images/social/instagram.png";
import facebook from "../images/social/facebook.png";
const cartChangeEvent = new Event("cartChange");

function Header() {
  const [cartCount, setCartCount] = useState();
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const updateCartCount = () => {
      const cartProducts =
        JSON.parse(localStorage.getItem("cartProducts")) || [];
      setCartCount(cartProducts.length);
    };

    updateCartCount();
    window.addEventListener("cartChange", updateCartCount);

    return () => window.removeEventListener("cartChange", updateCartCount);
  }, []);

  useEffect(() => {
    if (search !== "") {
      axios
        .get(
          `https://officeservice.co.il/php_files/search.php?search=${search}`
        )
        .then((res) => {
          setResults(res.data.slice(0, 3));
        });
    } else {
      setResults([]);
    }
  }, [search]);
  function resetSearch() {
    setSearch("");
    setResults([]);
  }
  useEffect(() => {
    // Load the Lordicon script dynamically
    const script = document.createElement("script");
    script.src = "https://cdn.lordicon.com/bhenfmcm.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div id="header-container" className="">
      <div id="small-header" className="">
        <div id="div1Header">
          <p className="searchLabel">חיפוש באתר</p>
          <div className="search-container">
            <input
              type="text"
              name="searchBar"
              id="searchBar"
              placeholder="חיפוש באתר"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  // 13 is the keyCode for Enter
                  e.preventDefault();
                  // Navigate to the search page with the current search query
                  window.location.href = `/תוצאות-חיפוש/${encodeURIComponent(
                    search
                  )}`;
                  // Clear the search input field
                  setSearch("");
                }
              }}
              autoComplete="off"
            />

            {results.length > 0 && (
              <div className="search-results">
                {results.map((product) => (
                <Link
                      key={product.id}
                      to={`/מוצרים/${product.id}`}
                      state={(product = product)}
                      onClick={resetSearch}
                      className="search-result"
                    >
                  <div className="search-result">
                    <img
                      src={
                        product.colors
                          ? product.colorsImages.split(",")[0]
                          : product.mainImage
                      }
                      alt={product.productName}
                    />
                    <p>
                      {product.productName}</p>
                    </div>
                    </Link>
                ))}
                {results.length > 0 && (
                  <Link
                    to={`/תוצאות-חיפוש/${encodeURIComponent(search)}`}
                    onClick={resetSearch}
                    className="watch-results-style"
                  >
                    צפייה בכל התוצאות
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
        <div id="div2Header">
          <a
            href="https://www.instagram.com/office_gift_service/"
            target="_blank"
          >
            <img
              src={instagram}
              alt="instagram-logo"
              className="social-header-icon"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100005175634037"
            target="_blank"
          >
            <img
              src={facebook}
              alt="facebook-logo"
              className="social-header-icon"
            />
          </a>
          <a
            href="https://www.google.com/maps?q=ברקת+10,+פתח+תקווה"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <>
              <lord-icon
                src="https://cdn.lordicon.com/burmxlrn.json"
                trigger="loop"
                delay="500"
                colors="primary:#00afee,secondary:#ffffff"
                stroke="125"
                state="hover-jump"
                style={{ width: "30px", height: "30px" }}
              ></lord-icon>
            </>
            <p className="smallText">ברקת 10, פתח תקווה</p>
          </a>
          <a
            href="mailto:office.erez@gmail.com"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <>
              <lord-icon
                src="https://cdn.lordicon.com/rpztpstw.json"
                trigger="loop"
                delay="500"
                colors="primary:#ffffff,secondary:#00afee"
                stroke="100"
                style={{ width: "30px", height: "30px" }}
              ></lord-icon>
            </>
            <p className="smallText">office.erez@gmail.com</p>
          </a>

          <>
            <lord-icon
              src="https://cdn.lordicon.com/ltxkhbfa.json"
              trigger="loop"
              delay="500"
              colors="primary:#00afee,secondary:#ffffff"
              stroke="125"
              style={{ width: "30px", height: "30px" }}
            ></lord-icon>
          </>
          <p className="smallText">077-50-52-54-6</p>

          <>
            <lord-icon
              src="https://cdn.lordicon.com/zxbujmvx.json"
              trigger="loop"
              delay="500"
              colors="primary:#ffffff,secondary:#00afee"
              stroke="100"
              state="hover-1"
              style={{ width: "30px", height: "30px" }}
            ></lord-icon>
          </>

          <p className="smallText">052-4243635</p>
        </div>
      </div>
      <div id="main-header" className="">
        <div id="rightDiv">
          <NavLink end to="/" className="links">
            דף הבית
          </NavLink>
          <NavLink to="/מי-אנחנו" className="links">
            אודותינו
          </NavLink>
          <NavLink to="/קטגוריות" className="links">
            המוצרים שלנו
          </NavLink>
          <NavLink to="/תיק-עבודות" className="links">
            תיק עבודות
          </NavLink>
        </div>
        <div id="leftDiv">
          <HeaderContactButton />
          <Link to="/סל-מוצרים">
            <i className="fas fa-shopping-cart"></i>
            {cartCount > 0 && (
              <span key={cartCount} className="cart-count">
                {cartCount}
              </span>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
