import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CartPage.css";

function CartPage() {
  const [cartProducts, setCartProducts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const storedCartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setCartProducts(storedCartProducts);
    setIsLoading(false);
  }, []);


  const handleRemoveProduct = (id) => {
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    const newCartProducts = cartProducts.filter(
      (product) => product.id !== id
    );
    localStorage.setItem("cartProducts", JSON.stringify(newCartProducts));
    setCartProducts(newCartProducts);
    window.dispatchEvent(new CustomEvent("cartChange"));
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const fullName = e.target.fullName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;

    const emailDetails = {
      fullName,
      email,
      phone,
      cartProducts: cartProducts.map((product) => ({
        name: product.productName,
        quantity: document.querySelector(
          `input[name="quantity"][data-id="${product.id}"]`
        ).value,
        comment: document.querySelector(
          `textarea[name="comment"][data-id="${product.id}"]`
        ).value,
      })),
    };

    const response = await fetch(
      "https://officeservice.co.il/php_files/send.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailDetails),
      }
    );

    const text = await response.text();
    console.log("Raw Response:", text);

    try {
      const result = JSON.parse(text);
      if (response.ok) {
        alert(result.message);
        localStorage.setItem("cartProducts", JSON.stringify([]));
        setCartProducts([]);
        window.dispatchEvent(new CustomEvent("cartChange"));
        e.target.reset();
        setIsSubmitted(true);
      } else {
        alert(`Error: ${result.message}`);
      }
    } catch (e) {
      console.error("Error parsing JSON:", e);
      console.log("Server response:", text);
    }

    setIsSubmitting(false);
  };
  const updateLocalStorage = () => {
    localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
  };
  
  const handleQuantityChange = (id, newQuantity) => {
    const updatedCartProducts = cartProducts.map(product =>
      product.id === id ? { ...product, amount: newQuantity } : product
    );
    setCartProducts(updatedCartProducts);
    updateLocalStorage();  // Update local storage
  };
  const handleCommentChange = (id, newComment) => {
    const updatedCartProducts = cartProducts.map(product =>
      product.id === id ? { ...product, notes: newComment } : product
    );
    setCartProducts(updatedCartProducts);
    updateLocalStorage();  // Update local storage
  };
  return (
    <div id="cart-page-container">
      {isLoading ? (
        <div className="loading-message">טוען מוצרים...</div>
      ) : isSubmitted ? (
        <div className="thank-you-message">
          <h2>תודה על הזמנתך!</h2>
          <p>הזמנתך הושלמה בהצלחה. נצור איתך קשר בהקדם.</p>
          <Link to="/">חזור לדף הבית</Link>
        </div>
      ) : (
        <>
          <p id="cart-page-title">סל המוצרים</p>
          {cartProducts.length === 0 ? (
            <>
              <p>סל המוצרים ריק</p>
              <Link
                style={{ color: "#2da0e4", fontSize: "1.5rem" }}
                to="/קטגוריות"
              >
                למעבר לחנות
              </Link>
            </>
          ) : (
            <>
              <table className="cart-page-table">
                <thead className="cart-page-thead">
                  <tr>
                    <th>שם המוצר:</th>
                    <th>כמות:</th>
                    <th>הערות:</th>
                  </tr>
                </thead>
                <tbody className="cart-page-tbody">
                  {cartProducts.map((product) => (
                    <tr key={product.id}>
                      <td>
                        <div className="cart-page-product-wrapper">
                          <img
                            src={
                              product.colors === ""
                                ? product.mainImage
                                : product.colorsImages[0]
                            }
                            alt={product.productName}
                            className="cart-page-product-image"
                          />
                          <p>{product.productName}</p>
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="quantity"
                          data-id={product.id}
                          min="1"
                          className="cart-page-quantity"
                          defaultValue={product.amount}
                          onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                        />
                      </td>
                      <td>
                        <textarea
                          name="comment"
                          data-id={product.id}
                          className="cart-page-comment"
                          placeholder="הערות (אני רוצה את הצבע הצהוב...)"
                          defaultValue={product.notes}
                          onChange={(e) => handleCommentChange(product.id, e.target.value)}
                        />
                      </td>
                      <td>
                        <lord-icon
                          onClick={() => handleRemoveProduct(product.id)}
                          src="https://cdn.lordicon.com/gsqxdxog.json"
                          trigger="hover"
                          colors="primary:#282828,secondary:#282828"
                          stroke="70"
                          state="hover-empty"
                          style={{ width: "40px", height: "40px", cursor: "pointer" }}
                        ></lord-icon>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <form
                className="cart-page-contact-form"
                onSubmit={handleSubmit}
                required
              >
                <input
                  type="text"
                  name="fullName"
                  placeholder="שם מלא / חברה"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="אימייל"
                  required
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="מספר טלפון"
                  required
                />
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <span className="spinner"></span> : "שלח"}
                </button>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default CartPage;
