import React, { useState, useRef } from "react"; // Import useRef
import CategoryList from "./CategoryList";
import "./AddCategory.css";

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryStack, setCategoryStack] = useState([]);
  const [file, setFile] = useState(null);
  const [haveProducts, setHaveProducts] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const formRef = useRef(); // Define formRef
  const handleRemoveCategory = async (categoryId) => {
    const userConfirm = window.confirm("האם אתה בטוח שברצונך להסיר את הקטגוריה הזאת?");
    if(userConfirm) {
    console.log("Removing category with ID:", categoryId);
    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/remove_category.php",
        {
          method: "POST",
          body: JSON.stringify({ categoryId }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();

      if (data.success) {
        alert(data.message);
        // Update your state or refresh categories
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("categoryName", categoryName);
    formData.append(
      "parentId",
      selectedCategory ? selectedCategory.id : "main"
    );
    formData.append("imageUrl", file);
    formData.append("haveProducts", haveProducts ? 1 : 0);

    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/add_category.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.success) {
        alert(data.message);
        formRef.current.reset(); // Reset the form
        setCategoryName(""); // Reset the state
        setFile(null); // Reset the state
        setHaveProducts(false); // Reset the state
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleImageChange = async (e) => {
    const newImage = e.target.files[0];

    const formData = new FormData();
    formData.append("image", newImage);
    formData.append("categoryId", selectedCategory.id); // Assuming selectedCategory has the ID

    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/change_image.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.success) {
        setSelectedImage(data.imageUrl); // Update the image URL in the state
        alert(data.message);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCategorySelect = (category) => {
    setCategoryStack([...categoryStack, selectedCategory]);
    setSelectedCategory(category);
  };

  const handleBack = () => {
    const newCategoryStack = [...categoryStack];
    const lastCategory = newCategoryStack.pop();
    setSelectedCategory(lastCategory);
    setCategoryStack(newCategoryStack);
  };
  const handleRenameCategory = async () => {
    const newName = prompt('Enter the new name for the category:');
    if(newName) {
        try {
            let formData = new FormData();
            formData.append('oldCategoryName', selectedCategory.categoryName);
            formData.append('newCategoryName', newName);

            const response = await fetch(
                "https://officeservice.co.il/php_files/rename_category.php",
                {
                    method: "POST",
                    body: formData,
                }
            );

            const data = await response.json();
            if (data.success) {
                alert(data.message);
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }
};

  return (
    <div className="add-category-container">
      <h2 style={{ fontSize: "30px", fontWeight: "700" }}>הוספת קטגוריה</h2>
      <CategoryList
        onCategorySelect={handleCategorySelect}
        parentId={selectedCategory?.id}
      />
      {selectedCategory && (
        <div>
          <p style={{fontSize: "20px", fontWeight: "700"}}>הקטגוריה שנבחרה: {selectedCategory.categoryName}</p>
          <button
            className="change-image"
            onClick={() => document.getElementById("changeImageInput").click()} // Trigger file input
          >
            שנה תמונה של {selectedCategory.categoryName}
          </button>
          <input
            type="file"
            id="changeImageInput"
            name="changeImage"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <button
            className="remove-category"
            onClick={() => handleRemoveCategory(selectedCategory?.id)} // Make sure this is the correct ID
          >
            הסר קטגוריה
          </button>
          <button
        className="rename-category"
        onClick={handleRenameCategory}
      >
        שנה שם
      </button>
          <button className="back-to-category" onClick={handleBack}>
            חזור ל-
            {categoryStack[categoryStack.length - 1]?.categoryName || "ראשי"}
          </button>
        </div>
      )}

      <form ref={formRef} onSubmit={handleSubmit} className="add-category-form">
        <input
          type="text"
          id="categoryName"
          name="categoryName"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="שם הקטגוריה"
          required
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <p>תמונת הקטגוריה:</p>{" "}
          <input
            type="file"
            id="categoryImage"
            name="categoryImage"
            onChange={handleFileChange}
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100vw",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <p>האם יש לקטגוריה מוצרים?</p>
          <input
            type="checkbox"
            checked={haveProducts}
            onChange={(e) => setHaveProducts(e.target.checked)}
          />
        </div>
        <button type="submit">הוסף קטגוריה</button>
      </form>
    </div>
  );
};

export default AddCategory;
