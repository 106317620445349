import React, { useState, useEffect, useRef } from "react";
import { BsCartPlus, BsCartFill } from "react-icons/bs";
import { CiHeart } from "react-icons/ci";
import { Link } from "react-router-dom";
import "./ProductCard.css";
const cartChangeEvent = new Event("cartChange");

const ProductCard = ({ product }) => {
  const [isLoved, setIsLoved] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [faBoxOpacity, setFaBoxOpacity] = useState(0);
  const [faBoxTop, setFaBoxTop] = useState("-100px");

  const [showDiv, setShowDiv] = useState(false);
  const [amount, setAmount] = useState(1);
  const [notes, setNotes] = useState('');
  const handleAmountChange = (e) => setAmount(e.target.value);
const handleNotesChange = (e) => setNotes(e.target.value);

  const cartButtonRef = useRef(null);
  const cardRef = useRef(null);

  const imageSource = product.colors
    ? product.colorsImages.split(",")[0]
    : product.mainImage;

    useEffect(() => {
      const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
      
      // Check if cartProducts contains a product with matching id
      const productInCart = cartProducts.some(cartProduct => cartProduct.id === product.id);
      
      setIsInCart(productInCart);
    
      if (cartButtonRef.current) {
        cartButtonRef.current.addEventListener("click", () => {
          cartButtonRef.current.classList.add("clicked");
        });
      }
    }, [product.id]);
    

  useEffect(() => {
    if (isInCart) {
      setFaBoxOpacity(1);
      setFaBoxTop("37%");
    } else {
      setFaBoxOpacity(0);
      setFaBoxTop("-10px");
    }
  }, [isInCart]);

  const handleCartClick = (e) => {
    e.preventDefault();  // Prevent navigation
  
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    const productInCart = cartProducts.some(cartProduct => cartProduct.id === product.id);
  
    if (productInCart) {
      // Product is in cart, remove it
      const updatedCartProducts = cartProducts.filter(cartProduct => cartProduct.id !== product.id);
      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
      setIsInCart(false);  // Update isInCart state
      setFaBoxOpacity(0);  // Update faBoxOpacity state
      setFaBoxTop("-10px");  // Update faBoxTop state
      window.dispatchEvent(cartChangeEvent);  // Notify of cart change
    } else {
      // Product is not in cart, show the div to add it
      setShowDiv(true);
    }
  };
  
  

  const saveToLocalStorage = () => {
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    const productDetails = { ...product, amount, notes };
    if(cartProducts.some(cartProduct => cartProduct.id === product.id)) {
      const updatedCartProducts = cartProducts.map(cartProduct =>
        cartProduct.id === product.id ? productDetails : cartProduct
      );
      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
    } else {
      cartProducts.push(productDetails);
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
    }
    setShowDiv(false);  // Hide the new div
    setIsInCart(true);
    window.dispatchEvent(cartChangeEvent);
  };
  const closeDiv = () => {
    setShowDiv(false);  // Hide the div when close button is clicked
};
  
  const displayTitle = product.productName.length > 20 
  ? product.productName.substring(0, 30) + "..." 
  : product.productName;
  return (
    <div>
      {showDiv && (
        <>
      <div className="overlay-product"></div>
            <div id="input-div-product">
                <button className="close-button" onClick={closeDiv}>X</button>
      <input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="כמות"
      />
      <input
        type="text"
        value={notes}
        onChange={handleNotesChange}
        placeholder="הערות"
      />
      <button onClick={saveToLocalStorage}>שמירה והמשך קניות</button>
      <Link id="input-div-product-link" to="/סל-מוצרים" onClick={saveToLocalStorage}>שמירה ומעבר לסל קניות</Link>
    </div>
    </>
  )}
    <Link
      to={{
        pathname: `/מוצרים/${encodeURIComponent(product.id)}`,
        state: { product: product },
      }}
      className="product-card-link"
    >
      <div ref={cardRef} className="product-card">
        <div className="product-image-container">
          <img src={imageSource} alt={product.productName.substring(0, 20)} />
        </div>
        <h2>{displayTitle}</h2>
        <button
          ref={cartButtonRef}
          onClick={handleCartClick}
          className="cart-button"
        >
          <i className="fas fa-shopping-cart"></i>
          <i
            className="fas fa-box"
            style={{ opacity: 1, top: faBoxTop }}
          ></i>
        </button>
      </div>
    </Link>
    </div>
  );
};

export default ProductCard;
