import React, { useState, useEffect, useRef } from "react";
import CategoryCard from "../components/Home components/CategoryCard";
import ProductCard from "../components/Home components/ProductCard";
import "./CategoriesPage.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import erezIcon from "../images/erez-icon.png";

const CategoriesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryStack, setCategoryStack] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [pathArray, setPathArray] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [message, setMessage] = useState("");
  const [fetching, setFetching] = useState(false);
  const fetchStatus = useRef({ fetching: false });

  const getFullPath = (idSlug = "", newCategoryStack) => {
    let path = "/קטגוריות";
    if (newCategoryStack.length > 0) {
      path += "/" + newCategoryStack.map((c) => c.id).join("/");
      path = path.endsWith("/") ? path.slice(0, -1) : path;
    }
    return path;
  };

  const handleCategorySelect = (category) => {
    if (fetchStatus.current.fetching) {
      return;
    }

    const newCategoryStack = [...categoryStack, category];
    setCategoryStack(newCategoryStack);
    const newPath = getFullPath("", newCategoryStack);
    navigate(newPath);
  };

  // Added useEffect hook
  useEffect(() => {
    setMessage("");
    if (isUpdating) {
      setIsUpdating(false);
    }
  }, [categoryStack]);

  useEffect(() => {
    const pathArray = location.pathname.split("/").slice(2);
    setPathArray(pathArray);

    const abortController = new AbortController();

    const fetchCategory = async (id) => {
      const response = await fetch(
        `https://officeservice.co.il/php_files/fetch_inventory.php?parentId=${id}`,
        { signal: abortController.signal }
      );
      const data = await response.json();
      console.log("fetchCategory response:", data); // <-- Log the full response here
      if (data.length > 0) {
        return data[0];
      } else {
        return { id, categoryName: id };
      }
    };

    const fetchCategories = async () => {
      fetchStatus.current.fetching = true;
      try {
        const categoryPromises = pathArray.map(fetchCategory);
        const categories = await Promise.all(categoryPromises);
        setCategoryStack(categories);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      } finally {
        setIsLoading(false); // set loading to false after fetch
        fetchStatus.current.fetching = false;
      }
    };

    fetchCategories();

    return () => {
      abortController.abort();
      setFetching(false);
    };
  }, [location]);

  const fetchProducts = async (parentCategoryId) => {
    const response = await fetch(
      `https://officeservice.co.il/php_files/fetch_products.php?parentCategoryId=${parentCategoryId}`
    );
    const data = await response.json();
    console.log("fetchProducts response:", data);
    return data.products;
  };

  useEffect(() => {
    const parentId = pathArray[pathArray.length - 1] || "main";
    console.log(
      "Fetching categories/products for parentId:",
      decodeURIComponent(parentId)
    );
  
    const abortController = new AbortController();
  
    fetch(
      `https://officeservice.co.il/php_files/fetch_inventory.php?parentId=${parentId}`,
      { signal: abortController.signal }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.error(response.error);
          setCategories([]); // clear categories
          setMessage("There was an error fetching the data.");
          return;
        }
  
        const data = response.data || [];
        let specialIds = ["בקבוקים", "טקסטיל", "כובעים", "מחברות", "תיקים", "כלי כתיבה"];
        let lastCategory = "מוצרים מגניבים";
        let alcoholOrder = ["וויסקי", "יין", "בירה", "פותחנים", "משחקי אלכוהול"];
  
        let firstCategories = data.filter((cat) => specialIds.includes(cat.categoryName));
        firstCategories.sort((a, b) => specialIds.indexOf(a.categoryName) - specialIds.indexOf(b.categoryName));
  
        let lastCategories = data.filter((cat) => cat.categoryName === lastCategory);
  
        let otherCategories = data.filter((cat) => !specialIds.includes(cat.categoryName) && cat.categoryName !== lastCategory);
  
        if (decodeURIComponent(parentId) === decodeURIComponent("מוצרי-אלכוהול")) {
          console.log("Before sorting alcohol categories:", otherCategories); // Debugging line
          otherCategories.sort((a, b) => alcoholOrder.indexOf(a.categoryName) - alcoholOrder.indexOf(b.categoryName));
          console.log("After sorting alcohol categories:", otherCategories); // Debugging line
        } else {
          console.log("parent id isn't מוצרי-אלכוהול");
          otherCategories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        }
        
  
        const newCategories = [...firstCategories, ...otherCategories, ...lastCategories];
        setCategories(newCategories);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error:", error);
          setCategories([]); // clear categories
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  
    return () => {
      abortController.abort();
      console.log("AbortController has been called to abort fetch operation.");
    };
  }, [location, pathArray]);
  
  
  useEffect(() => {
    const lastCategory = pathArray[pathArray.length - 1] || "main";
    fetchProducts(decodeURIComponent(lastCategory))
      .then((fetchedProducts) => {
        setProducts(fetchedProducts);
      })
      .catch((error) => {
        if (error.name !== "AbortError") {
          console.error("Error fetching products:", error);
          setProducts([]);
        }
      });
  }, [pathArray]);
  useEffect(() => {
    console.log("Categories state updated:", categories);
  }, [categories]);

  return (
    <div className="categories-container">
      <h2 style={{ fontSize: "3.5rem", fontWeight: "700", color: "#282828" }}>
        קטגוריות
      </h2>
      <div className="category-links">
        <Link to="/קטגוריות" onClick={() => setIsLoading(true)}>
          ראשי
        </Link>

        {categoryStack.length > 0 && " / "}
        {categoryStack
          .map((category, index) => {
            if (index < categoryStack.length - 1) {
              return (
                <Link
                  key={category.id}
                  to={getFullPath("", categoryStack.slice(0, index + 1))}
                  onClick={() => setIsLoading(true)}
                >
                  {decodeURIComponent(category.categoryName)}
                </Link>
              );
            } else {
              return (
                <span key={category.id}>
                  {decodeURIComponent(category.categoryName)}
                </span>
              );
            }
          })
          .reduce((prev, curr, i) => {
            if (i !== 0) {
              return [...prev, " / ", curr];
            } else {
              return [curr];
            }
          }, [])}
      </div>
      {!isloading && (
        <div>
          {/* Categories Container */}
          <div className="category-cards-container">
            {categories && categories.length > 0 && (
              <CategoryCard
                categories={categories}
                onCategorySelect={handleCategorySelect}
                isLoading={isloading}
              />
            )}
          </div>
          {products && products.length > 0 && (
            <div className="products-container-style">
              <h1>מוצרים</h1>
              <div className="products-container">
                {products.map((product) => (
                  <ProductCard key={product.id} product={product} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {message && (
        <div className="message-container">
          <p>{message}</p>
        </div>
      )}
            <div id="product-gallery-container">
            <img src={erezIcon} alt="icon" />
        <p>בואו לראות דוגמאות אמיתיות למוצרים שעשינו למגוון לקוחותינו</p>
        <Link to="/תיק-עבודות">למעבר לתיק העבודות שלנו</Link>
      </div>
    </div>
  );
};

export default CategoriesPage;
