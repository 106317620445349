import React, { useState, useEffect } from "react";
import CategoryCard from "./CategoryCard";
import "./CategoriesTemplate.css";
import { useNavigate } from "react-router-dom";
import CategoryCardItself from "./CategoryCardItself";

const CategoriesTemplate = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleCategorySelect = (category) => {
    navigate(`/קטגוריות/${category.id}`);
  };

  useEffect(() => {
    console.log("Fetching special categories...");
  
    // Replace the URL with the location of your new PHP script
    fetch("https://officeservice.co.il/php_files/home-categories.php")
      .then((res) => {
        console.log("Response received:", res);
        return res.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        if (data.status === "success") {
          const categoriesArray = Array.isArray(data.data)
            ? data.data
            : [];
          setCategories(categoriesArray);
        } else {
          console.error("Error fetching categories:", data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      });
  }, []);
  

  return (
    <div className="categories-container">
      <p id="categories-title" className="title">
        קטגוריות מובילות
      </p>
      <div className="category-cards">
        {loading ? (
          <p>טוען קטגוריות...</p>
        ) : categories.length > 0 ? (
          categories?.map((category) => (
            <CategoryCardItself
              key={category.id}
              category={category}
              onCategorySelect={handleCategorySelect}
            />
          ))
        ) : (
          <p>No categories found</p>
        )}
      </div>
    </div>
  );
};

export default CategoriesTemplate;
