import React from "react";
import "./AboutSection.css";
import { Link } from "react-router-dom";
import wave from "../../images/wave.svg";

import officeImage from "../../images/background1.jpg"; // Replace with the actual image path

function AboutSection() {
  return (
    <div id="about-container">
      <div className="image-container">
        <img src={officeImage} alt="Office" className="office-image" />
      </div>
      <div className="text-container">
        <p id="about-title">מי אנחנו?</p>
        <p id="about-description">אופיס סרוויס פועלת ומובילה משנת 2008 בתחום של שיווק מתנות לעסק ומתנות לכל מטרה. אצלנו תמצאו מגוון מרשים וגדול של מוצרי קידום מכירות לשיווק עסקים וחברות - קטנים וגדולים.
        </p>
        <Link to="/מי-אנחנו" className="readMoreStyle">
          קרא עוד
        </Link>
      </div>
    </div>
  );
}

export default AboutSection;
