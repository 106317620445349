import React from "react";
import "./ContactPage.css";
import { MdEmail, MdLocationOn, MdLocalPhone } from "react-icons/md";

function ContactPage() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements["full-name"].value;
    const email = e.target.elements.email.value;
    const message = e.target.elements.message.value;
  
    // Fetch for inserting contact
    const responseInsert = await fetch(
      "https://officeservice.co.il/php_files/insert_contact.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      }
    );
    const dataInsert = await responseInsert.json();
  
    // Fetch for sending SMS via your send_sms.php file
    const responseSMS = await fetch(
      "https://officeservice.co.il/php_files/send_sms.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      }
    );
    const dataSMS = await responseSMS.json();
  
    console.log('Insert Data:', dataInsert);
    console.log('SMS Data:', dataSMS);
  };
  
  return (
    <div className="contact-container">
      <p id="contact-title">
        דעתכם חשובה לנו,{" "}
        <span style={{ fontWeight: 700 }}>אנחנו כאן בשבילכם!</span>
      </p>
      <p id="contact-description">
        אנחנו זמינים לכל שאלה, תגובה או בקשה בטלפון ובמייל
      </p>
      <div id="contact-page-form-container">
        <div id="contact-form">
          <form id="contact-page-form" onSubmit={handleSubmit}>
            <div className="input-row">
              <input
                type="text"
                id="full-name"
                name="fullName"
                placeholder="שם מלא"
                className="contact-name-field"
                required
              />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="אימייל"
                className="contact-email-field"
                required
              />
            </div>
            <textarea
              id="message"
              name="message"
              placeholder="הודעה"
              required
            ></textarea>
            <input type="submit" value="קבל הצעת מחיר" />
          </form>
        </div>
        <div id="contact-details-container">
          <p id="details-title">פרטים ליצירת קשר</p>
          <p id="details-description">
            אנחנו זמינים עבורכם באימייל, בטלפון ובסניף
          </p>
          <div className="detail-item">
            <a href="mailto:office.erez@gmail.com">
              <MdEmail size={45} />

              <p>office.erez@gmail.com</p>
            </a>
          </div>
          <div className="detail-item">
            <a
              href="https://www.google.com/maps?q=ברקת+10,+פתח+תקווה"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdLocationOn size={45} />

              <p>ברקת 10, פתח תקווה</p>
            </a>
          </div>
          <div className="detail-item">
            <a href="tel:077-505-2546">
              <MdLocalPhone size={45} />

              <p>077-505-2546</p>
            </a>
          </div>
          <div className="detail-item">
            <a href="tel:+972524243635">
              <MdLocalPhone size={45} />

              <p>052-4243635</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
