function IdoFooter() {
    return (
      <div 
        id="ido-footer-container" 
        style={{
          width: "100%",
          height: "50px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#282828",
          color: "white"
        }}
      >
        <p>האתר נבנה על ידי Ido - בניית אתרים | idoaviram14@gmail.com | 053-4263577</p>
      </div>
    );
  }
  
  

export default IdoFooter;