import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Cursor from "./components/Cursor";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import SideImg from "./components/SideImg";
import CategoriesPage from "./pages/CategoriesPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import WhatsappFixed from "./components/whatsappFixed";
import AdminPage from "./pages/AdminPage";
import AdminLogin from "./pages/AdminLogin";
import CheckHeader from "./components/CheckHeader";
import SearchPage from "./pages/SearchPage";
import GalleryPage from "./pages/GalleryPage";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import IdoFooter from "./components/IdoFooter";


function App() {
  const location = useLocation();
  return (
    <div className="App">
      <div className="AppContainer">
      <CheckHeader />
      <WhatsappFixed />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/קטגוריות/*" element={<CategoriesPage />} />
        <Route path="/מוצרים/:id" element={<ProductPage />} />
        <Route path="/תיק-עבודות" element={<GalleryPage />} />
        <Route path="/מי-אנחנו" element={<AboutPage />} />
        <Route path="/יצירת-קשר" element={<ContactPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/סל-מוצרים" element={<CartPage />} />
        <Route path="/תוצאות-חיפוש/:searchQuery" element={<SearchPage />} />
      </Routes>
      <SideImg />
      </div>
      <IdoFooter />
    </div>
  );
}

export default App;
