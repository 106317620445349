import React, { useState, useEffect } from "react";
import "./Form.css";
import RadioOptions from "./RadioOptions";

function Form() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [slideSelected, setSlideSelected] = useState(0);
  const slidesCount = 5;
  const [selectedOptions, setSelectedOptions] = useState(
    Array(slidesCount).fill("")
  );
  const slidesInfo = [
    {
      title: "למי מיועדת המתנה?",
      options: [
        "מתנות / 'סוואג' למשתתפי כנסים",
        'מוצרים מיתוג ופרסום / קד"מ',
        "מתנות לעובדים",
        "מתנות ללקוחות",
        "מתנות לחגים",
        "אחר",
      ],
    },
    { title: "כמה יחידות?", options: ["20-50", "50-100", "מעל 100"] },
    {
      title: "מה המחיר הרצוי ליחידה?",
      options: ['מתחת ל-20 ש"ח', '20-50 ש"ח', '50-100 ש"ח', 'מעל 100 ש"ח'],
    },
    {
      title: "עוד משהו שאנחנו צריכים לדעת לפני שניתן לך הצעת מחיר?",
      options: [],
    },
    { title: "השאר פרטים ונציג יחזור אליך בהקדם עם הצעה", options: [] },
  ];
  const slideRefs = slidesInfo.map(() => React.createRef());
  const submitForm = () => {
    if (!validateSubmission()) {
      return;
    }
    // Collect user information from the inputs
    const fullName = document.querySelector("#clientNameInput").value;
    const phone = document.querySelector("#clientPhoneInput").value;
    const companyName = document.querySelector("#clientCompanyNameInput").value;
    const email = document.querySelector("#clientEmailInput").value;
    const comment = document.querySelector("#notesInput").value;
    const formData = {
      selectedOptions,
      fullName,
      phone,
      companyName,
      email,
      comment,
    };
  
    // Make AJAX request to your quick_offer.php file
    fetch("https://officeservice.co.il/php_files/quick_offer.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        setIsSubmitted(true);
        // Make another AJAX request to your send_order_sms.php file
        return fetch("https://officeservice.co.il/php_files/send_order_sms.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          alert('SMS sent successfully');
        } else {
          alert('SMS sending failed');
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error here
      });
     setIsSubmitted(true);
     console.log(isSubmitted);
  };
  

  const handleOptionChange = (slideIndex, event) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions[slideIndex] = event.target.value;
    setSelectedOptions(newSelectedOptions);
  };
  const validateSubmission = () => {
    // Check if all options have been selected
    for (let i = 0; i < selectedOptions.length; i++) {
      if (slidesInfo[i].options.length > 0 && selectedOptions[i] === "") {
        alert(`אנא בחר אופצייה בשאלה ${i + 1}`);
        return false;
      }
    }

    // Validate the client inputs
    const fullName = document.querySelector("#clientNameInput").value;
    const phone = document.querySelector("#clientPhoneInput").value;
    const companyName = document.querySelector("#clientCompanyNameInput").value;
    const email = document.querySelector("#clientEmailInput").value;

    if (
      !fullName.trim() ||
      !phone.trim() ||
      !companyName.trim() ||
      !email.trim()
    ) {
      alert("אנא מלא את כל הפרטים");
      return false;
    }

    return true;
  };

  const nextSlide = () => {
    if (
      slidesInfo[slideSelected].options.length > 0 &&
      selectedOptions[slideSelected] === ""
    ) {
      alert("אנא בחר אופצייה");
      return;
    }
    setSlideSelected((prevState) =>
      prevState === slidesCount - 1 ? 0 : prevState + 1
    );
  };

  const prevSlide = () => {
    setSlideSelected((prevState) =>
      prevState === 0 ? slidesCount - 1 : prevState - 1
    );
  };

  useEffect(() => {
    const slideElement = document.getElementById("slides-container");
    slideElement.style.transform = `translateX(${20 * slideSelected}%)`;
    const currentSlideRef = slideRefs[slideSelected].current;
    if (currentSlideRef) {
      const height = currentSlideRef.clientHeight;
      slideElement.style.height = `${height}px`;
    }
  }, [slideSelected]);


  return (
    <div id="hero-form-container">
      <p id="form-title">הצעת מחיר מהירה</p>
      <div id="hero-form-card">
        {isSubmitted ? (
          <p style={{ color: "white", textAlign: "center", padding: "20px", fontSize: "20px"}}>
            תודה שפנית אלינו, נחזור אליך בהקדם!
          </p>
        ) : (
          <>
            <div id="slides-container">
              {slidesInfo.map((slide, index) => (
                <div
                  key={index}
                  id={`slide${index}`}
                  className="form-slide"
                  ref={slideRefs[index]}
                >
                  <p id={`slide-title${index}`} className="slide-title">
                    {slide.title}
                  </p>
                  {index === 3 ? (
                    <textarea
                      id="notesInput"
                      placeholder="*לא חובה - הדפסה מיוחדת, הנחיות, עזרה במציאת המוצר או כל דבר אחר - אנו כאן להקשיב"
                    ></textarea>
                  ) : index === 4 ? (
                    <div id={`options${index}`}>
                      <input
                        id="clientNameInput"
                        type="text"
                        placeholder="שם מלא"
                        required
                      />
                      <input
                        id="clientPhoneInput"
                        type="tel"
                        placeholder="מספר טלפון"
                        required
                      />
                      <input
                        id="clientCompanyNameInput"
                        type="text"
                        placeholder="שם החברה"
                        required
                      />
                      <input
                        id="clientEmailInput"
                        type="email"
                        placeholder="אימייל"
                        required
                      />
                      {index === slidesCount - 1 && (
                        <button id="quick-submit-button" onClick={submitForm}>
                          שלח הצעה
                        </button>
                      )}
                    </div>
                  ) : slide.options.length > 0 ? (
                    <RadioOptions
                      name={`options${index}`}
                      options={slide.options}
                      selectedOption={selectedOptions[index]}
                      onChange={(event) => handleOptionChange(index, event)}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div id="hero-form-buttons-container">
              <button
                id="next"
                style={{
                  visibility:
                    slideSelected === slidesCount - 1 ? "hidden" : "visible",
                }}
                onClick={nextSlide}
                className="navigate-slide"
              >
                הבא
              </button>
              <button
                id="previous"
                style={{
                  visibility: slideSelected === 0 ? "hidden" : "visible",
                }}
                onClick={prevSlide}
                className="navigate-slide"
              >
                הקודם
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Form;
