import React, { useState, useRef } from "react";
import CategoryList from "./CategoryList";
import "./AddProduct.css";
import { useEffect } from "react";

const AddProduct = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryStack, setCategoryStack] = useState([]);
  const [colors, setColors] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(); // Create a ref
  const colorsInputRef = useRef([]); // Ref for color inputs
  const colorsImageRef = useRef([]); // Ref for color image inputs
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const uniqueImages = editingProduct && editingProduct.colorsImages 
  ? [...new Set(editingProduct.colorsImages)] 
  : [];
  const [images, setImages] = useState({
    mainImage: null,
    colorsImages: []
  });
  const [categories, setCategories] = useState([]);
  const newColorImageRef = useRef([]); // Ref for new color images
const newMainImageRef = useRef(); // Ref for new main image
const [newColorImages, setNewColorImages] = useState([]); // New state for new color images
  const [newMainImage, setNewMainImage] = useState(null); // New state for new main image

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("parentCategoryId", selectedCategory.id);
    formData.append("description", description);
    colors.forEach((color, index) => {
      const colorNameInput = document.querySelector(`#colorName${index}`);
      const colorImageInput = document.querySelector(`#colorImage${index}`);
      if (colorNameInput && colorImageInput) {
        formData.append(`colorName${index}`, colorNameInput.value);
        formData.append(`colorImage${index}`, colorImageInput.files[0]);
      }
    });
    // Only append the main image if it's not undefined
    formData.append("mainImage", fileInputRef.current.files[0] || "");
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
  }
  
    fetch("https://officeservice.co.il/php_files/add_products.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUploading(false);
        fileInputRef.current.value = "";
        setProductName("");
        setDescription("");
        setColors([]);
        fetchProducts();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const removeColor = (indexToRemove) => {
    setColors(colors.filter((_, index) => index !== indexToRemove));
  };
  const fetchProducts = async () => {
    const response = await fetch(
      `https://officeservice.co.il/php_files/fetch_products.php?parentCategoryId=${selectedCategory.id}`
    );
    const data = await response.json();
    setFetchedProducts(data.products || []);
  };
  useEffect(() => {
    if (selectedCategory) {
      fetchProducts();
    }
  }, [selectedCategory]);
  const handleDeleteProduct = async (id) => {
    const userConfirmed = window.confirm('האם אתה בטוח שברצונך למחוק את המוצר?');
    if (!userConfirmed) return;

    const response = await fetch(`https://officeservice.co.il/php_files/delete_product.php?id=${id}`);
    const data = await response.json();
    if (!data.error) {
      fetchProducts();  // Refresh products after deletion
    } else {
      console.error(data.error);
    }
  };
  const handleTransfer = (productId) => {
    // Get the selected category ID from the dropdown
    const selectedCategoryId = document.getElementById(`category-select-${productId}`).value;
    console.log(selectedCategoryId);
    console.log(productId);
    // Send request to PHP to update the product's category
    fetch('https://officeservice.co.il/php_files/transfer_product.php', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
          productId: productId,
          categoryId: selectedCategoryId
      }),
  })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
          alert("המוצר הועבר בהצלחה!");
          fetchProducts();
      } else if (data.error) {
          alert("הייתה שגיאה בהעברת המוצר: " + data.error);
      } else {
          console.log(data); // Log the received data for debugging
          alert("Unexpected server response");
      }
  })
  
    .catch(error => {
        console.error("There was an error transferring the product", error);
    });
}
  const handleEditProduct = async (id) => {
    const response = await fetch(`https://officeservice.co.il/php_files/fetch_product.php?id=${id}`)
    const data = await response.json();
  
    console.log("Fetched Product Data:", data);
    setEditingProduct(data);
  }
  const handleUpdateProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productId", encodeURIComponent(editingProduct.id));
    formData.append("productName", editingProduct.productName);
    formData.append("description", editingProduct.description);
    formData.append("parentCategoryId", selectedCategory.id);

    let existingUrls = [];
    let newColorImagesCount = 0; // To keep track of new color images being uploaded

    if (editingProduct.colorsImages && editingProduct.colorsImages.length > 0) {
        editingProduct.colorsImages.forEach((item, index) => {
            if (typeof item === 'string') { // item is a URL
                existingUrls.push(item);
            } else { // item is a File object
                formData.append(`colorImage_${index}`, item);
                newColorImagesCount++;
            }
        });
        formData.append("existingUrls", existingUrls.join(','));
    }

      formData.append("newMainImage", editingProduct.mainImage);
      console.log(editingProduct.mainImage);
  

    try {
        const response = await fetch('https://officeservice.co.il/php_files/update_product.php', {
            method: 'POST',
            body: formData
        });

        const responseBody = await response.text();

        try {
            const data = JSON.parse(responseBody);

            if (response.ok) {
                console.log("Product updated:", data.message);
                alert("המוצר התעדכן בהצלחה!");
                setEditingProduct("");
                fetchProducts();
            } else {
                console.log("An error occurred:", data.error);
            }
        } catch (error) {
            console.log("Received non-JSON response:", responseBody);
        }
    } catch (error) {
        console.log("An error occurred:", error);
    }
};

  
  
  const handleCategorySelect = async (category) => {
    setCategoryStack([...categoryStack, selectedCategory]);
    setSelectedCategory(category);

    const response = await fetch(
      `https://officeservice.co.il/php_files/check_for_products.php?categoryId=${category.id}`
    );
    const data = await response.json();
    console.log(data);

    setIsFormDisabled(!data);
  };

  const handleImagesChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    if (selectedImages.length === 1) {
      setImages({
        mainImage: selectedImages[0],
        colorsImages: []
      });
    } else {
      setImages({
        mainImage: null,
        colorsImages: selectedImages
      });
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    // Filter out the image to be removed.
    const remainingImages = uniqueImages.filter((_, index) => index !== indexToRemove);
    
    // Update mainImage and colorsImages based on the total number of images left.
    const isSingleImage = remainingImages.length === 1;
    
    setEditingProduct(prevState => ({
      ...prevState,
      mainImage: isSingleImage ? remainingImages[0] : "",
      colorsImages: isSingleImage ? [] : remainingImages
    }));
    console.log(remainingImages);
  };
  
  const handleBack = () => {
    const newCategoryStack = [...categoryStack];
    const lastCategory = newCategoryStack.pop();
    setSelectedCategory(lastCategory);
    setCategoryStack(newCategoryStack);
  };

  const addColor = () => {
    setColors([...colors, { name: "", imageUrl: "" }]);
  };
  const removeImageAtIndex = (index) => {
    const newImages = [...images.colorsImages];
    newImages.splice(index, 1);
    setImages((prevState) => ({
      mainImage: newImages.length === 1 ? newImages[0] : prevState.mainImage,
      colorsImages: newImages.length > 1 ? newImages : []
    }));
  };
  useEffect(() => {
    // Fetch the categories from the PHP script
    fetch('https://officeservice.co.il/php_files/get_all_categories.php')
        .then(response => response.json())
        .then(data => {
            setCategories(data);
        })
        .catch(error => {
            console.error("There was an error fetching categories", error);
        });
}, []);
  const handleAddImages = () => {
    // Get the new images.
    const newImages = Array.from(newColorImageRef.current.files);
  
    // Check the total number of images.
    const totalImages = uniqueImages.length + newImages.length;
  
    // Scenario where there's only one image in total.
    if (totalImages === 1) {
      const singleImage = newImages.length ? newImages[0] : uniqueImages[0];
      setEditingProduct(prevState => ({
        ...prevState,
        mainImage: singleImage,
        colorsImages: []
      }));
      console.log(editingProduct.mainImage);
      console.log(editingProduct.colorsImages);
      return;
    }
  
    // Scenario for multiple images.
    // Keep the main image as it is and just append new images to colorsImages.
    setEditingProduct(prevState => ({
      ...prevState,
      colorsImages: [...prevState.colorsImages, ...newImages]
    }));
  };
  
  return (
    <div className="add-product-container">
      <h2 style={{ fontSize: "30px", fontWeight: "700" }}>הוספת מוצר</h2>

      {uploading && <progress value={progress} max="100" />}
      <CategoryList
        onCategorySelect={handleCategorySelect}
        parentId={selectedCategory?.id}
      />      {selectedCategory && (
        <p style={{fontSize: "20px", fontWeight: "700"}}>הקטגוריה שנבחרה: {selectedCategory.categoryName}</p>
      )}
      {selectedCategory && (
        <button className="back-to-category" onClick={handleBack}>
          חזור ל-{categoryStack[categoryStack.length - 1]?.name || "ראשי"}
        </button>
      )}<form onSubmit={handleSubmit} className="add-product-form">
        <input
          type="text"
          id="productName"
          name="productName"
          value={productName}
          placeholder="שם המוצר"
          onChange={(e) => setProductName(e.target.value)}
          required
        />
        <textarea
          id="description"
          name="description"
          value={description}
          placeholder="תיאור המוצר"
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>
        {colors.map((color, index) => (
          <div key={index}>
            <input
              ref={(el) => (colorsInputRef.current[index] = el)}
              type="text"
              id={`colorName${index}`}
              name="colorName"
              placeholder="שם הצבע"
              required
            />
            <input
              ref={(el) => (colorsImageRef.current[index] = el)}
              type="file"
              id={`colorImage${index}`}
              name="colorImage"
              required
            />
            <button
              type="button"
              style={{ color: "black" }}
              onClick={() => removeColor(index)}
            >
              הסר צבע
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addColor}
          style={{ color: "white", backgroundColor: "#00afee" }}
        >
          הוסף צבע
        </button>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p>תמונת המוצר</p>
          <input type="file" ref={fileInputRef}/>
        </div>
        <button type="submit" disabled={!selectedCategory}>
          הוסף מוצר
        </button>
      </form>
      <div className="fetched-products">
      {fetchedProducts.map((product) => (
    <div key={product.id} className="fetched-product">
      <img src={product.mainImage != "NULL" && product.mainImage != "" ? product.mainImage : product.colorsImages.split(",")[0]} alt={product.productName} />
      <h3>{product.productName}</h3>
      <button id="edit-product-button" onClick={() => handleEditProduct(product.id)}>ערוך מוצר</button>
      <select id={`category-select-${product.id}`}>
            {categories.map(categoryId => (
                <option key={categoryId} value={categoryId}>
                    {categoryId}
                </option>
            ))}
        </select>
        <button id="delete-product-button" onClick={() => handleDeleteProduct(product.id)}>מחק מוצר</button>
      <button id="transfer-button" onClick={() => handleTransfer(product.id)}>העבר לקטגוריה הנבחרת</button>
    </div>
))}
{editingProduct && (
  <div className="edit-product-form">
    <div id="edit-product-container">
    <form id="editing-form" onSubmit={handleUpdateProduct}>
    <div id="product-images-container">

              <div className="editing-thumbnail-container">
              <div id="editing-thumbnail-div">
              {uniqueImages && uniqueImages.length > 0 ? (
  uniqueImages.map((image, index) => (
    <div key={index}>
      <img src={image} alt={`Color variant ${index}`} />
      <button onClick={() => handleRemoveImage(index)}>הסר</button>
    </div>
  ))
) : (
    <img src={editingProduct.mainImage} alt="Main Product Image" />

)}

    <input type="file" ref={newColorImageRef} multiple />
    <button type="button" onClick={handleAddImages}>הוסף תמונות</button>
</div>
              </div>
            </div>
            <div>
            <p>שם מוצר:</p>
      <input
        type="text"
        value={editingProduct.productName}
        onChange={(e) => {
          const newEditingProduct = { ...editingProduct, productName: e.target.value };
          setEditingProduct(newEditingProduct);
        }}
      />
      <br />
      <br />
      <p>תיאור מוצר:</p>
      <textarea value={editingProduct.description} onChange={(e) => {
          const newEditingProduct = { ...editingProduct, description: e.target.value };
          setEditingProduct(newEditingProduct);
        }} />
      <button type="submit">עדכן מוצר</button></div>
    </form>
    <button onClick={() => setEditingProduct(null)} className="cancel-editing">ביטול</button>
  </div>
  </div>
)}
</div>

      
    </div>
  );
};

export default AddProduct;
