import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "./ProductPage.css";
import erezIcon from "../images/erez-icon.png";

const cartChangeEvent = new Event("cartChange");

const ProductPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [product, setProduct] = useState(
    location.state ? location.state.product : null
  );
  const [showDiv, setShowDiv] = useState(false);
const [amount, setAmount] = useState(1);
const [notes, setNotes] = useState('');
const handleAmountChange = (e) => setAmount(e.target.value);
const handleNotesChange = (e) => setNotes(e.target.value);
  const [isLoading, setIsLoading] = useState(!product);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isInCart, setIsInCart] = useState(false);
 const uniqueImages = product && product.colorsImages 
    ? [...new Set(product.colorsImages)] 
    : []; 
  useEffect(() => {
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://officeservice.co.il/php_files/fetch_product.php?id=${id}`
        );
        const data = await response.json();
        console.log("Fetched Product Data:", data);
        if (data.debug) {
          console.log("Debug info:", data.debug);
        }

        console.log("Fetched Product Data:", data);
        console.log("Fetched Colors Images:", JSON.stringify(data.colorsImages));

        setProduct(data);
      } catch (error) {
        console.error("Failed to fetch product:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setIsInCart(cartProducts.includes(id));

    // Fetch the product data only if there is no product in the location state
    if (!location.state || !location.state.product) {
      fetchProductData();
    }
  }, [id]);

  const handleCartClick = (e) => {
    e.preventDefault();  // Prevent navigation
  
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    const productInCart = cartProducts.some(cartProduct => cartProduct.id === product.id);
  
    if (productInCart) {
      // Product is in cart, remove it
      const updatedCartProducts = cartProducts.filter(cartProduct => cartProduct.id !== product.id);
      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
      setIsInCart(false);  // Update isInCart state
      window.dispatchEvent(cartChangeEvent);  // Notify of cart change
    } else {
      // Product is not in cart, show the div to add it
      setShowDiv(true);
    }
  };
  
  

  const saveToLocalStorage = () => {
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    const productDetails = { ...product, amount, notes };
    if(cartProducts.some(cartProduct => cartProduct.id === product.id)) {
      const updatedCartProducts = cartProducts.map(cartProduct =>
        cartProduct.id === product.id ? productDetails : cartProduct
      );
      localStorage.setItem("cartProducts", JSON.stringify(updatedCartProducts));
    } else {
      cartProducts.push(productDetails);
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
    }
    setShowDiv(false);  // Hide the new div
    setIsInCart(true);
    window.dispatchEvent(cartChangeEvent);
  };
  const closeDiv = () => {
    setShowDiv(false);  // Hide the div when close button is clicked
};
  useEffect(() => {
    // Check if there is a product in the location state
    if (location.state && location.state.product) {
      // If so, update the product state
      setProduct(location.state.product);
    }
  }, [id, location.state]);

  return (
    <div className="product-page-container">
            {showDiv && (
        <>
      <div className="overlay-product"></div>
            <div id="input-div-product">
                <button className="close-button" onClick={closeDiv}>X</button>
      <input
        type="number"
        value={amount}
        onChange={handleAmountChange}
        placeholder="כמות"
      />
      <input
        type="text"
        value={notes}
        onChange={handleNotesChange}
        placeholder="הערות"
      />
      <button onClick={saveToLocalStorage}>שמירה והמשך קניות</button>
      <Link id="input-div-product-link" to="/סל-מוצרים" onClick={saveToLocalStorage}>שמירה ומעבר לסל קניות</Link>
    </div>
    </>
  )}
      {isLoading ? (
        <p>טוען מוצר...</p>
      ) : product ? (
        <>
          <div id="product-container">
            <div id="product-images-container">
              <img
                className="main-image"
                src={
                  product.colors
                    ? product.colorsImages[selectedImageIndex]
                    : product.mainImage
                }
                alt={product.productName}
              />
              <div className="thumbnail-container">
                {product.colors &&
                  uniqueImages.map((image, index) => (
                    <img
                      key={index}
                      className={`thumbnail ${
                        index === selectedImageIndex ? "active" : ""
                      }`}
                      onError={(e) => {
                        e.target.style.display = 'none';
                      }}
                      src={image}
                      alt={`Color variant ${index}`}
                      onClick={() => setSelectedImageIndex(index)}
                    />
                  ))}
              </div>
            </div>
            <div id="product-details">
              <p id="product-title">{product.productName}</p>
              <p
                id="product-description"
                dangerouslySetInnerHTML={{
                  __html: product.description.replace(/\n/g, "<br />"),
                }}
              />
              <button
                id="product-add-to-cart"
                className={isInCart ? "in-cart" : ""}
                onClick={handleCartClick}
              >
                {isInCart ? "המוצר בסל במוצרים" : "הוסף מוצר לסל"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <p>המוצר לא נמצא</p>
      )}

      <div id="product-gallery-container">
        <img src={erezIcon} alt="icon" />
        <div>
        <p>בואו לראות דוגמאות אמיתיות למוצרים שעשינו למגוון לקוחותינו</p>
        <Link to="/תיק-עבודות">למעבר לתיק העבודות שלנו</Link>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
