import React, { useRef, useState, useEffect } from "react";
import "./AddImageToGallery.css";

const AddImageToGallery = () => {
  const fileInputRef = useRef();
  const [title, setTitle] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
    const fetchImages = async () => {
      const response = await fetch("https://officeservice.co.il/php_files/get_gallery_images.php");
      const data = await response.json();
      setGalleryImages(data.images);
    };
  useEffect(() => {


    fetchImages();
  }, []);

  const handleDelete = async (id) => {
    try {
      if (window.confirm("האם אתה בטוח שברצונך למחוק את התמונה?")) {
        const response = await fetch(
          `https://officeservice.co.il/php_files/delete_image.php?id=${id}`,
          {
            method: "DELETE",
          }
        );
  
        if (response.ok) {
          alert("Image deleted successfully!");
          fetchImages();
        } else {
          alert("An error occurred while deleting the image.");
        }
      }
    } catch (error) {
      console.error("There was an error deleting the image", error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", fileInputRef.current.files[0]);
    formData.append("title", title);

    const response = await fetch(
      "https://officeservice.co.il/php_files/add_images_to_gallery.php",
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();

    console.log(data);

    if (response.ok) {
      alert("התמונה הועלתה בהצלחה!"); // Alert for successful upload
      setTitle(""); // Optional: Clear the title input after a successful upload
    } else {
      alert("אירעה שגיאה בהעלאת התמונה. אנא נסה שנית."); // Alert for a failed upload
    }
    fetchImages();
  };

  return (
    <div className="admin-add-image-container">
      <h2 className="add-image-title">הוספת תמונה לתיק עבודות</h2>
      <form onSubmit={handleSubmit} className="add-image-form">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="כותרת התמונה"
          className="add-image-input-text"
          required
        />
        <input
          type="file"
          ref={fileInputRef}
          className="add-image-input-file"
          required
        />
        <button type="submit" className="add-image-button">
          העלאת תמונה
        </button>
      </form>
      <div className="gallery-images-admin">
        {galleryImages.map((image) => (
          <div key={image.title} className="gallery-image-admin">
            <img src={image.url} alt={image.title} />
            <p className="image-title-admin">{image.title}</p>
            <button onClick={() => handleDelete(image.id)}>מחק</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddImageToGallery;
