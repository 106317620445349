import React from "react";
import TypingAnimation from "../components/TypingAnimation";
import "./AboutPage.css";
import ContactUs from "../components/Home components/ContactSection";
import Form from "../components/Home components/Form";

function AboutPage() {
  const texts = ["מיתוג", "הפקה", 'קד"מ', "מתנות"];
  const colors = [
    "color1",
    "color2",
    "color3",
    "color4",
  ];
  return (
    <div id="about-page-container">
      <TypingAnimation
        prefix="אנחנו מוכרים מוצרי "
        textArray={texts}
        colorArray={colors}
      />
      <div className="about-content-section">
        <h1 className="about-title">אופיס סרוויס - יבוא ושיווק מתנות ומוצרי פרסום</h1>
        <p className="about-description">
          אופיס סרוויס פועלת ומובילה משנת 2008 בתחום של שיווק מתנות לעסק ומתנות לכל מטרה. אצלנו תמצאו מגוון מרשים וגדול של מוצרי קידום מכירות לשיווק עסקים וחברות - קטנים וגדולים.<br /> אנו מזמינים אתכם לגלוש באתר ולהתרשם בעצמכם מהמבחר העצום של מוצרי שיווק, פרסום וקד"מ - מוצרים מיוחדים בדגש על מיתוג אישי.
        </p>
        <br />
        <p className="about-description">
          עולם הפרסום מתפתח ללא הרף משנה לשנה, מה שמצריך בעלי עסקים להתגמש ולהתאים את עצמם בהתאם לשינויים והטרנדים האחרונים בתחום. אנו מאמינים כי קידום מכירות זוהי דרך מצוינת לחדור את מודעות קהל הלקוחות שלנו, מה שמוכיח את עצמו פעם אחר פעם. <br />בנוסף לשיווק מתנות לעסקים אנחנו מעניקים שירותי הדפסה על מוצרי מיתוג וקידום מכירות - בשיטות דפוס שונות כגון: הדפסת uv, משי, טמפון, חריטת לייזר, סובלימציה, רקמה ועוד... דפוס פרוצס(צבעוני) ללא הגבלת צבעים ברמה הגבוהה ביותר.
        </p>
        <br />
        <h1 className="about-title">רוצים להפוך למותג?</h1>
        <p className="about-description">          
        אנו מזמינים אתכם להתרשם ממבחר המוצרים שלנו ובין היתר: מתנות ללקוחות, מתנות לעובדים, מוצרים לכנסים ואירועים שונים, מוצרי קד"מ עם טוויסט ייחודי שלא תמצאו במקומות אחרים - כל אלו על מנת להפוך את המותג שלכם למותג חזק ובולט מהמתחרים שלכם!
        </p>
        <br />
        <p className="about-description" style={{textAlign: "center"}}>
          צרו קשר עוד היום עם חברתנו ותהנו ממבחר מוצרי פרסום ומתנות איכותיים ומתנות מקוריות.
        </p>
     
      </div>
      <div id="about-form-container">
        <ContactUs />
      </div>
    </div>
  );
}

export default AboutPage;
